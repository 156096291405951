<template>
  <div class="flex flex-col lg:flex-row justify-center mx-4 sm:mx-12">
    <div
      :class="[
        'flex justify-center mx-auto py-5 xl:flex xl:justify-center xl:items-center lg:w-6/12 lg:max-w-full',
        {
          'lg:order-1': props.position === 'right',
          'lg:order-2': props.position === 'left',
          'order-2': props.mobilePosition === 'top',
          'order-1': props.mobilePosition === 'bottom',
        },
      ]"
    >
      <img
        :src="props.image.src"
        :alt="props.image.alt"
        :class="props.image.class || 'max-w-xs lg:w-3/4 xl:w-full h-auto'"
        :style="{
          maxWidth: '100%',
          height: props.image.height || 'auto',
          width: props.image.width || '100%',
        }"
      />
    </div>
    <div
      class="flex flex-col mx-auto text-center xl:flex xl:justify-start mt-4 lg:flex-col lg:w-6/12 sm:mx-6 md:mx-8 lg:mx-0"
      :class="[
        {
          'lg:order-2': props.position === 'right',
          'lg:order-1': props.position === 'left',
          'order-1': props.mobilePosition === 'top',
          'order-2': props.mobilePosition === 'bottom',
        },
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  image: {
    src: string;
    alt: string;
    class?: string;
    width?: string;
    height?: string;
  };
  position: "left" | "right";
  mobilePosition?: "top" | "bottom";
}>();
</script>

<style scoped>
/* css style */
</style>
